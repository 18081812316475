/* eslint-disable max-len */
/* eslint-disable max-statements */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable react/jsx-max-depth */
import React from 'react'
import { withRouter } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { Experiment, Variant } from 'react-optimize'
import queryString from 'query-string'
import isEmpty from 'lodash/isEmpty'
import { Button, Dropdown, PageLoader } from '@mymoons/ui-library'
import styles from './Branches.module.css'
import messages from './Branches.messages'
import Utils from '../../Utils/utils'
import addressIcon from '../../Assets/images/svg/address.svg'
import calendarIcon from '../../Assets/images/svg/calendar.svg'
import moon from '../../Assets/images/svg/moon.svg'
import filterIcon from '../../Assets/images/svg/filter.svg'
import closeIcon from '../../Assets/images/svg/close_filter.svg'
import moonGray from '../../Assets/images/svg/moon_gray.svg'
import discount from '../../Assets/images/svg/discount.svg'
import 'reactjs-popup/dist/index.css'
import './styles-pop-up.css'
import modalImage from '../../Assets/images/png/modal.png'
import { setTrackingPatient, setTrackingRudder } from '../../Utils/tracking'

const statesLabel = {
  México: 'Estado',
  Colombia: 'Departamento',
  Perú: 'Departamento',
}

/**
 * Branches Component
 * @return {React.Component} all smile centers
 */
class Branches extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)

    let search = {}
    if (props && props.history.location && props.history.location.search) {
      search = queryString.parse(props.history.location.search)
      if (search.state) {
        delete search.state
      }
    }
    props.history.push(`?${new URLSearchParams(search).toString()}`)

    this.state = {
      isLoading: false,
      branches: [],
      state: '',
      city: '',
      center: '',
      states: [],
      cities: [],
      centers: [],
      showFilter: false
    }
  }

  isValidUrl = (link) => {
    if (isEmpty(link)) {
      return false
    }
    try {
      // eslint-disable-next-line no-new
      new URL(link.trim())
      return true
    } catch (err) {
      console.log(err)
    }
    return false
  }

  trackEvent(branch) {
    const { setCurrentBranch, patient } = this.props
    if (branch.whatsAppCall === true && this.isValidUrl(branch.whatsAppLink)) {
      window.open(branch.whatsAppLink.trim(), '_blank')
    } else {
      setCurrentBranch(branch)
    }
    const data = {
      distinct_id: patient.CustomerId,
      customer_id: patient.CustomerId,
      country_ops: patient.Country_Ops,
      center_name: branch.Center_Name,
      platform: 'appointment_platform_v2'
    }
    setTrackingPatient(patient, { name: 'Old Appointment Platform - Center Selected', data })
    try {
      window.dataLayer.push({ 'event': 'center_selected', 'center': branch.Center_Name });
    } catch (err) {
      console.log(err)
    }
    setTrackingRudder('branch_click', { platform: 'new_appointment', country_ops: patient.Country_Ops, customer_id: patient.CustomerId, branch_name: branch.Center_Name });
  }

  /**
   * Component Did Mount
   * @return {void}
   */
  componentDidMount = () => {
    const { items, patient } = this.props
    if (items.length > 0) {
      const statesUnique = new Set(items.map(item => item.State))
      const citiesUnique = new Set(items.map(item => item.City))
      const centersUnique = new Set(items.map(item => item.Center_Type === 'Doctores Moons' ? 'Centro Aliado' : item.Center_Type))
      const states = [...statesUnique].filter(item => item !== '').sort((a, b) => a.localeCompare(b, 'en'))
      const cities = [...citiesUnique].filter(item => item !== '').sort((a, b) => a.localeCompare(b, 'en'))
      const centers = [...centersUnique].filter(item => item !== '').sort(this.sortCenters)

      const _branches = []
      const grouped = items.reduce(
        (result, currentValue) => {
          (result[currentValue.Center_Type] = result[currentValue.Center_Type] || []).push(currentValue)
          return result
        }, {}
      )
      let groupedKeys = Object.keys(grouped).filter(element => element.toLocaleLowerCase().includes('centro moons'))
      if (groupedKeys) {
        const centers = []
        groupedKeys.forEach(element => {
          centers.push(...grouped[element])
          delete grouped[element]
        })
        centers.sort((a, b) => a.Order - b.Order)
        _branches.push(...centers)
      }
      groupedKeys = Object.keys(grouped).filter(element => element.toLocaleLowerCase().includes('liverpool'))
      if (groupedKeys) {
        const centers = []
        groupedKeys.forEach(element => {
          centers.push(...grouped[element])
          delete grouped[element]
        })
        centers.sort((a, b) => a.Order - b.Order)
        _branches.push(...centers)
      }

      if (grouped) {
        const centers = []
        Object.keys(grouped).forEach(element => {
          centers.push(...grouped[element])
        })
        centers.sort((a, b) => a.Order - b.Order)
        _branches.push(...centers)
      }
      this.setState({
        branches: this.mapBrachByRegion(_branches),
        states: [null, ...states].map((item, index) => ({ id: index ? index.toString() : 'initial', value: item, label: item || statesLabel[patient.Country_Ops] })),
        cities: [null, ...cities].map((item, index) => ({ id: index ? index.toString() : 'initial', value: item, label: item || 'Ciudad' })),
        centers: [null, ...centers].map((item, index) => ({ id: index ? index.toString() : 'initial', value: item, label: item || 'Tipo de Centro' })),
      })
    }
  }

  /**
   * Map Brach By State
   * @param {Object} items .
   * @return {void}
   */
  mapBrachByRegion = (items) => {
    const branches = {}
    Array.isArray(items) && items.map((branch) => {
      if (!branches[branch.Region]) {
        branches[branch.Region] = []
      }
      branches[branch.Region].push(branch)
      return ''
    })
    return branches
  }

  /**
   * Get branches title
   * @param {string} key .
   * @return {string} title
   */
  getBranchesTitle = (key) => {
    const { patient } = this.props
    if (key === 'Centros Aliados' && patient.Country_Ops === 'Colombia') {
      return 'Otros centros aliados'
    }
    return key
  }

  /**
   * Handle Apply Filter Click
   * @return {void}
   */
  handleApplyFilterClick = (isClear) => {
    const { items, patient } = this.props
    const { state, city, center } = this.state
    const statesUnique = new Set(items.map(item => item.State))
    const citiesUnique = new Set(items.map(item => item.City))
    const centersUnique = new Set(items.map(item => item.Center_Type === 'Doctores Moons' ? 'Centro Aliado' : item.Center_Type))
    const states = [...statesUnique].filter(item => item !== '').sort((a, b) => a.localeCompare(b, 'en'))
    const cities = [...citiesUnique].filter(item => item !== '').sort((a, b) => a.localeCompare(b, 'en'))
    const centers = [...centersUnique].filter(item => item !== '').sort(this.sortCenters)

    this.setState({ isLoading: true })
    const filteredItems = items.filter((item) => {
      if (state && state.id !== 'initial' && item.State !== state.value) {
        return false
      }
      if (city && city.id !== 'initial' && item.City !== city.value) {
        return false
      }
      if (center && center.id !== 'initial' && item.Center_Type !== (center.value)) {
        if (center.value === 'Centro Aliado' && item.Center_Type === 'Doctores Moons') {
          return true
        }
        return false
      }
      return true
    })
    const _branches = []
    const grouped = filteredItems.reduce(
      (result, currentValue) => {
        (result[currentValue.Center_Type] = result[currentValue.Center_Type] || []).push(currentValue)
        return result
      }, {}
    )
    let groupedKeys = Object.keys(grouped).filter(element => element.toLocaleLowerCase().includes('centro moons'))
    if (groupedKeys) {
      const centers = []
      groupedKeys.forEach(element => {
        centers.push(...grouped[element])
        delete grouped[element]
      })
      centers.sort((a, b) => a.Order - b.Order)
      _branches.push(...centers)
    }
    groupedKeys = Object.keys(grouped).filter(element => element.toLocaleLowerCase().includes('liverpool'))
    if (groupedKeys) {
      const centers = []
      groupedKeys.forEach(element => {
        centers.push(...grouped[element])
        delete grouped[element]
      })
      centers.sort((a, b) => a.Order - b.Order)
      _branches.push(...centers)
    }

    if (grouped) {
      const centers = []
      Object.keys(grouped).forEach(element => {
        centers.push(...grouped[element])
      })
      centers.sort((a, b) => a.Order - b.Order)
      _branches.push(...centers)
    }

    setTimeout(() => {
      if (isClear) {
        this.setState({
          branches: this.mapBrachByRegion(_branches),
          states: [null, ...states].map((item, index) => ({ id: index ? index.toString() : 'initial', value: item, label: item || statesLabel[patient.Country_Ops] })),
          cities: [null, ...cities].map((item, index) => ({ id: index ? index.toString() : 'initial', value: item, label: item || 'Ciudad' })),
          centers: [null, ...centers].map((item, index) => ({ id: index ? index.toString() : 'initial', value: item, label: item || 'Tipo de Centro' })),
          isLoading: false
        })
      } else {
        this.setState({ branches: this.mapBrachByRegion(_branches), isLoading: false })
      }
    }, 500)
  }

  /**
   * Handle Clear Filter Click
   * @return {void}
   */
  handleClearFilterClick = () => {
    this.setState({ state: null, city: null, center: null }, () => {
      this.handleApplyFilterClick(true)
    })
  }

  /**
   * Item Component
   * @param {Object} key .
   * @param {Object} item .
   * @return {void}
   */
  itemComponent = (key, item) => {
    const { publicKey, patient } = this.props
    const country = patient.Country_Ops
    return (
      <div className={styles.BranchesContainer}>
        <p className={styles.KeyCenter}>{this.getBranchesTitle(key)}</p>
        <div
          key={key}
          className={styles.ItemContainer}
        >

          {Array.isArray(item) && item.map((branch, index) => {
            const {
              Center_Name, Neighborhood, Timetable, Prev_Videocall, promo
            } = branch
            const address = `${Neighborhood}`
            if (Prev_Videocall && Prev_Videocall === true && (country !== 'Colombia' || country !== 'colombia')) {
              return (
                <Experiment id="lmO2jJwnRRKDDA88i8cKxw">
                  <Variant id="0">
                    <div
                      className={[styles.CenterContainer, index === 0 ? styles.First : ''].join(' ')}
                      key={branch.Appointment_Type_Id}
                    >
                      <div className={styles.AddressContainer}>
                        <div className={styles.AddressPrimaryContainer}>
                          <p className={styles.Center}>{Center_Name}</p>
                          {address && (
                            <div className={styles.IconContainer}>
                              <img
                                className={styles.Icon}
                                alt="icon"
                                src={addressIcon}
                              />
                              <p className={styles.Address}>{address}</p>
                            </div>
                          )}
                          <div className={styles.IconContainer}>
                            <img
                              className={styles.Icon}
                              alt="icon"
                              src={calendarIcon}
                            />
                            <p className={styles.Schedule}>{Utils.parseTimeTable(Timetable)}</p>
                          </div>
                        </div>
                        <Button
                          size="medium"
                          color="red"
                          className={styles.SelectButton}
                          label={messages.select}
                          variant="outline"
                          onClick={() => this.trackEvent(branch)}
                        />
                      </div>
                    </div>
                  </Variant>
                  <Variant id="1">
                    <Popup
                      onOpen={() => {
                      }}
                      trigger={(
                        <div
                          className={[styles.CenterContainer, index === 0 ? styles.First : ''].join(' ')}
                          key={branch.Appointment_Type_Id}
                          role="button"
                          tabIndex={0}
                        >
                          <div className={styles.AddressContainer}>
                            <div className={styles.AddressPrimaryContainer}>
                              <p className={styles.Center}>{Center_Name}</p>
                              {address && (
                                <div className={styles.IconContainer}>
                                  <img
                                    className={styles.Icon}
                                    alt="icon"
                                    src={addressIcon}
                                  />
                                  <p className={styles.Address}>{address}</p>
                                </div>
                              )}
                              <div className={styles.IconContainer}>
                                <img
                                  className={styles.Icon}
                                  alt="icon"
                                  src={calendarIcon}
                                />
                                <p className={styles.Schedule}>{Utils.parseTimeTable(Timetable)}</p>
                              </div>
                            </div>
                            <Button
                              size="medium"
                              color="red"
                              className={styles.SelectButton}
                              label={messages.select}
                              variant="outline"
                              onClick={() => this.trackEvent(branch)}
                            />
                          </div>
                        </div>
                      )}
                      modal
                      nested
                      onClose={() => {
                        this.trackEvent(branch)
                      }}
                    >
                      {close => (
                        <div className="modal">
                          <div
                            className="close"
                            onClick={close}
                          >
                            &times;
                          </div>
                          <div className="content-wrapper">
                            <div className="modal-image">
                              <div>
                                <img
                                  className="image"
                                  src={modalImage}
                                  alt="aligners"
                                />
                              </div>
                            </div>
                            <div className="content-texts">
                              <div className="header">
                                <p className="header-text">
                                  {messages.modalTitle.one}
                                  <br />
                                  {messages.modalTitle.two}
                                </p>
                              </div>
                              <div className="content">
                                <p>
                                  {messages.modalContent.one}
                                  {/* replace centerName by the real name of the center */}
                                  <span className="bold">{messages.modalContent.two.replace('centerName', Center_Name)}</span>
                                  {messages.modalContent.three}
                                </p>
                              </div>
                              <div className="actions">
                                <div
                                  className="cta"
                                  onClick={() => {
                                    window.location.href = `https://precitas.mymoons.mx/${publicKey}`
                                  }}
                                >
                                  {messages.modalCTA}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Popup>
                  </Variant>
                </Experiment>
              )
            }
            return (
              <div
                className={styles.State}
              >
                <div
                  className={styles.CenterContainer}
                  key={branch.Appointment_Type_Id}
                >
                  <div className={styles.AddressContainer}>
                    <div className={styles.AddressPrimaryContainer}>
                      <p className={styles.Center}>
                        {Center_Name}
                        {Center_Name.includes('Polanco111') ? <img className={styles.discount} alt="icon" src={discount} /> : null}
                      </p>
                      {promo ? (
                        <div className={styles.IconContainer}>
                          <img
                            className={styles.IconPromo}
                            alt="icon"
                            src={moon}
                          />
                          <p className={styles.Promo}>
                            {promo}
                            *
                          </p>
                        </div>
                      ) : null}
                      {address && (
                        <div className={styles.IconContainer}>
                          <img
                            className={styles.Icon}
                            alt="icon"
                            src={addressIcon}
                          />
                          <p className={styles.Address}>{address}</p>
                        </div>
                      )}
                      <div className={styles.IconContainer}>
                        <img
                          className={styles.Icon}
                          alt="icon"
                          src={calendarIcon}
                        />
                        <p className={styles.Schedule}>{Utils.parseTimeTable(Timetable)}</p>
                      </div>
                    </div>
                    <Button
                      size="medium"
                      color="red"
                      className={styles.SelectButton}
                      label={messages.select}
                      variant="outline"
                      onClick={() => this.trackEvent(branch)}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }


  /**
   * Sort Centers
   *
   * @param {*} a
   * @param {*} b
   * @returns {number}
   */
  sortCenters = (a, b) => {
    let orderA = 0
    let orderB = 0
    if (a === 'Centro Moons') {
      orderA = 3
    }
    if (a === 'Liverpool') {
      orderA = 2
    }
    if (a === 'Centro Aliado' || a === 'Doctores Moons') {
      orderA = 1
    }
    if (b === 'Centro Moons') {
      orderB = 3
    }
    if (b === 'Liverpool') {
      orderB = 2
    }
    if (b === 'Centro Aliado' || b === 'Doctores Moons') {
      orderB = 1
    }
    return orderB - orderA
  }

  /**
   * Render
   * @returns {void}
   */
  render() {
    const {
      branches, state, states, city, cities, center, centers, isLoading, showFilter
    } = this.state
    const { name, items } = this.props

    const title = messages.titleDesktop
    const welcome = messages.welcome.replace('{Name}', name)

    return (
      <div className={styles.ContainerBranch}>
        <p className={styles.TitleName}>
          {welcome}
          <img className={styles.MoonIcon} alt="icon" src={moon} />
        </p>
        <p className={styles.Title}>
          {title}
          {' '}
          <img className={styles.MoonIconMobile} alt="icon" src={moon} />
        </p>
        <p className={styles.Filter}>{messages.filterBy}</p>
        <div className={`${showFilter ? styles.FilterOpenMobile : styles.FilterMobile}`}>
          <span>{messages.filterBy}</span>
          {showFilter ? <img alt="close" src={closeIcon} onClick={() => this.setState({ showFilter: false })} />
            : <img alt="filter" src={filterIcon} onClick={() => this.setState({ showFilter: true })} />}
        </div>

        <div className={`${styles.FilterContainer} ${showFilter ? '' : styles.HideFilters}`}>
          <Dropdown
            name="state"
            label=""
            defaultTitle={messages.state}
            value={state}
            onChange={(e) => {
              const citiesUnique = new Set(items.filter(item => e.value === null
                || item.State === e.value).map(item => item.City))
              const _cities = [...citiesUnique].filter(item => item !== '').sort((a, b) => a.localeCompare(b, 'en'))
              const centersUnique = new Set(items.filter(item => e.value === null
                || item.State === e.value).map(item => item.Center_Type === 'Doctores Moons' ? 'Centro Aliado' : item.Center_Type))
              const _centers = [...centersUnique].filter(item => item !== '').sort(this.sortCenters)
              this.setState({
                state: e,
                cities: [null, ..._cities].map((item, index) => ({ id: index ? index.toString() : 'initial', value: item, label: item || 'Ciudad' })),
                city: '',
                centers: [null, ..._centers].map((item, index) => ({ id: index ? index.toString() : 'initial', value: item, label: item || 'Tipo de Centro' })),
              }, () => {
                this.handleApplyFilterClick()
              })
            }}
            options={states}
            className={styles.Dropdown}
          />
          <Dropdown
            name="city"
            label=""
            defaultTitle={messages.city}
            value={city}
            onChange={(e) => {
              this.setState({ city: e }, () => {
                this.handleApplyFilterClick()
              })
            }}
            options={cities}
            className={styles.Dropdown}
          />
          <Dropdown
            label=""
            name="center"
            defaultTitle={messages.center}
            value={center}
            onChange={(e) => {
              this.setState({ center: e }, () => {
                this.handleApplyFilterClick()
              })
            }}
            options={centers}
            className={styles.Dropdown}
          />
          <Button
            size="medium"
            color="red"
            label={messages.clearFilter}
            variant="outline"
            className={styles.ApplyFilterButton}
            onClick={this.handleClearFilterClick}
          />
        </div>
        {isLoading && <PageLoader />}

        {!Utils.isEmptyObject(branches) ? (
          <div>
            {Object.entries(branches).map(([key, value]) => this.itemComponent(key, value))}
            {/*branches.map((item, index) => this.itemComponent(index, item))*/}
          </div>
        )
          : (
            <div className={styles.EmptyContainer}>
              <img
                alt="icon"
                src={moonGray}
              />
              <p>
                {messages.empty}
              </p>
            </div>
          )
        }
      </div>

    )
  }
}

export default withRouter(Branches)
