const messages = {
  back: 'Regresar',
  welcome: '¡Ya casi tienes tus Moons!',
  select: 'Selecciona un horario para conocer tu nueva sonrisa',
  timing: 'Duración cita: 40 minutos',
  selectTime: 'Selecciona un horario para conocer tu nueva sonrisa',
  noDate: 'No tenemos horarios en este día, selecciona otro',
  bookingTitle:'¿No encuentras horario?',
  bookingDescription:'Debido a la alta demanda de citas algunos horarios están saturados. Todos los días abrimos nuevos espacios, te invitarnos a visitarnos pronto o consultar las clínicas cercanas. También puedes contactarnos por WhatsApp para apoyarte.',
  bookingCta:'¡Quiero que me contacten!',
  priorityBookingTitle: 'Agendamiento prioritario',
  priorityBookingDescription: 'En menos de 24hrs nos pondremos en contacto contigo vía WhatsApp para ayudarte a agendar tu cita de escaneo. ¿Estás de acuerdo?',
  priorityBookingCta: 'Sí, estoy de acuerdo',
  priorityBookingCta2: 'Seguir buscando por mi cuenta',
  bookingSuccessTitle:'¡Todo listo!',
  bookingSuccessDescription: 'Hemos enviado tu información y en menos de 24hrs nos pondremos en contacto contigo via WhatsApp para ayudarte a agendar tu cita de escaneo.',
  bookingSuccessCta: 'Aceptar',
  bookingErrorTitle:'¡Error inesperado!!',
  bookingErrorDescription: 'No pudimos enviar la solicitud de tu agendamiento prioritario. ¿Quieres reintentar?',
  bookingErrorCta: 'Sí, gracias',
  bookingErrorCta2: 'Seguir buscando por mi cuenta',
  empty: 'Lo sentimos, ya no tenemos horarios disponibles para esta locación.',
  chooseCenter: 'Elegir otro centro',
}

export default messages
