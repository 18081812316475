// eslint-disable-next-line require-jsdoc
const createUrlParams = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const Journey_Variant = urlParams.get('Journey_Variant')
  const scenterPromo = urlParams.get('scenterPromo')
}

// eslint-disable-next-line require-jsdoc
export const paramsObject = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  // eslint-disable-next-line camelcase
  const Journey_Variant = urlParams.get('Journey_Variant')
  const scenterPromo = urlParams.get('scenterPromo')
  const services = urlParams.get('services')
  const utm_medium = urlParams.get('utm_medium')
  const utm_reason = urlParams.get('utm_reason')
  const utm_source = urlParams.get('utm_source')
  const utm_term = urlParams.get('utm_term')
  const transactionId = urlParams.get('transactionId')
  const agentId = urlParams.get('agentId')
  const agentName = urlParams.get('agentName')
  return {
    Journey_Variant,
    scenterPromo,
    services,
    utm_medium,
    utm_reason,
    utm_source,
    utm_term,
    transactionId,
    agentId,
    agentName
  }
}

export default createUrlParams
