/* eslint-disable camelcase */
/* eslint-disable complexity */
import React from 'react'
import moment from 'moment'
import 'moment/locale/es'
import queryString from 'query-string'
import { Button } from '@mymoons/ui-library'
import styles from './Accept.module.css'
import messages from './Accept.messages'
import LoaderSection from '../LoaderSection/LoaderSection'
import TimeCounter from '../TimeCounter/TimeCounter'
import TestimonialsCarousel from '../Carousel'
import testimony from '../../Utils/testimonials'
import Utils from '../../Utils/utils'
import Middleware from '../../Api/Middleware'
import { apiAppointment } from '../../Api/Appointment';
import {
  FREE_APPOINMENT_VARIANT_MEDELLIN,
  FREE_APPOINMENT_VARIANT_BUCARAMANGA,
  PROMO_APPOINMENT_VARIANT_MEDELLIN,
  PROMO_APPOINMENT_VARIANT_BUCARAMANGA,
} from '../../Utils/constants';
import maintainUrlSearch from '../../Utils/maintain-url-search'
import { setTrackingPatient, setTrackingRudder } from '../../Utils/tracking'
import { paramsObject } from '../../Utils/createUrlParams'
import addressIcon from '../../Assets/images/svg/address.svg'
import calendarIcon from '../../Assets/images/svg/calendar.svg'
import moon from '../../Assets/images/svg/moon.svg'

/**
 * Accept Component
 * @return {void}
 */
class Accept extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    this.state = {
      expiredTime: false,
      loader: false,
      error: false,
      repeat: true,
      tries: 1,
      newAppointment: {},
      preloadStep: ''
    }
  }

  componentDidMount() {
    try {
      console.log('dateSelected', this.props.dateSelected)
      const momentDate = moment(this.props.dateSelected)
      const weekday = momentDate.format('dddd')
      const date = momentDate.format('L')
      const time = momentDate.format('LT')
      const hoursToAppointment = momentDate.diff(moment(), 'hours')
      const {
        dateSelected, branch, customerId, country
      } = this.props
      window?.dataLayer?.push({ 'event': 'time_slot_selected', weekday, datetime: this.props?.dateSelected, date, time, hoursToAppointment });
      setTrackingRudder('date_time_click', { platform: 'new_appointment', country_ops: country, customer_id: customerId, branch_name: branch.Center_Name, datetime: dateSelected })
      window.scrollTo(0, 0);
    } catch (err) {
      console.log(err)
    }
  }

  branchChoose = () => {
    const { branch, dateSelected, country, requirePayment, crmAppointmentId, cardHolder, isFree } = this.props
    const { expiredTime, loader, error, preloadStep } = this.state

    const {
      Center_Name, Street, Number, Neighborhood, Appointment_Type_Id,
    } = branch
    const address = Street && Number && Neighborhood ? `${Street} ${Number}, ${Neighborhood}` : ''
    const date = moment(dateSelected).format('dddd DD MMMM')
    const time = Utils.getDateByCountryTimeZone(dateSelected, country, Appointment_Type_Id)
    const isCitaPromoFlow = this.props.promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN
      || this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA
      || this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_MEDELLIN
      || this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_BUCARAMANGA

    let appointmentId
    if (crmAppointmentId) {
      appointmentId = crmAppointmentId
    } else if (this.props && this.props.history.location && this.props.history.location.search) {
      const search = queryString.parse(this.props.history.location.search)
      if (search.rescheduleId) {
        appointmentId = search.rescheduleId
      }
    }

    return (
      <div
        className={styles.CenterContainer}
        key={branch.Appointment_Type_Id}
      >
        <div
          className={styles.Item}
        >
          <p className={styles.Info}>{messages.info}</p>
          {/* --------- */}
          {this.props.promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA || this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA
            ? (
              <>
                <div className={`${styles.AddressContainer} ${styles.promoAppointmentContainer}`}>
                  <div className={styles.AddressPrimaryContainer}>
                    <div className={styles.promoAppointmentTitleContainer}>
                      <p className={styles.Center}>{`Centro: ${Center_Name}`}</p>
                      <div className={styles.promoAppointmentTextContainer}>
                        {(this.props.promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA) && this.props.counterPromo
                          ? (
                            <>
                              <span />
                              <p className={styles.promoAppointmentText}>CITA GRATIS</p>
                            </>
                          )
                          : (
                            <>
                              <span>   </span>
                              <p>PROMO CITA</p>
                            </>
                          )}
                      </div>
                    </div>
                    {address && (
                      <div className={styles.IconContainer}>
                        <img
                          className={styles.Icon}
                          alt="icon"
                          src={addressIcon}
                        />
                        <p className={styles.Address}>{address}</p>
                      </div>
                    )}
                    <div className={styles.IconContainer}>
                      <img
                        className={styles.Icon}
                        alt="icon"
                        src={calendarIcon}
                      />
                      <p className={styles.Dates}>{`${date}, ${time}`}</p>
                    </div>
                  </div>
                </div>
              </>
            )
            : (
              <div className={styles.AddressContainer}>
                <div className={styles.AddressPrimaryContainer}>
                  <p className={styles.Center}>{`Centro: ${Center_Name}`}</p>
                  {address && (
                    <div className={styles.IconContainer}>
                      <img
                        className={styles.Icon}
                        alt="icon"
                        src={addressIcon}
                      />
                      <p className={styles.Address}>{address}</p>
                    </div>
                  )}
                  <div className={styles.IconContainer}>
                    <img
                      className={styles.Icon}
                      alt="icon"
                      src={calendarIcon}
                    />
                    <p className={styles.Dates}>{`${date}, ${time}`}</p>
                  </div>
                </div>
              </div>
            )
          }

        </div>
        <TimeCounter
          setExpired={this.setExpired}
          clearPopup={this.returnPopup}
          promoAppointment={this.props.promoAppointment}
          counterPromo={this.props.counterPromo}
          syncMinutes={this.props.syncMinutes}
          syncSeconds={this.props.syncSeconds}
        />
        {loader
          ? (
            <div className={styles.ContainerLoader}>
              <p className={styles.PreloadStep}>{preloadStep}</p>
              <LoaderSection />
            </div>
          ) : !expiredTime
          && (
            <>
              <div className={styles.ContainerButton}>
                {isCitaPromoFlow
                  ? (
                    <Button
                      id="confirm-appointment"
                      size="medium"
                      color="red"
                      label={this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_BUCARAMANGA || requirePayment || !this.props.counterPromo ? 'Agendar cita' : 'Agendar cita gratis'}
                      className={styles.SubmitButton}
                      onClick={() => this.submitData()}
                    />
                  )
                  : (
                    <Button
                      id="confirm-appointment"
                      size="medium"
                      color="red"
                      fullWidth={false}

                      label={isFree ? messages.confirmAppointmentFree : cardHolder ? messages.freeAppointment : requirePayment ? messages.payAppointment
                        : appointmentId
                          ? messages.rescheduleAppointment
                          : messages.confirmAppointment
                      }
                      className={styles.SubmitButton}
                      onClick={() => this.submitData()}
                    />
                  )
                }
                {isCitaPromoFlow ? null :
                  <Button
                    id="back-appointment"
                    size="medium"
                    color="red"
                    variant="outline"
                    label={messages.back}
                    className={styles.SubmitButton}
                    onClick={() => this.returnPopup()}
                  />}
              </div>
              {error && <p className={styles.Error}>{error}</p>}
            </>
          )
        }
      </div>
    )
  }

  setExpired = () => {
    this.setState({ expiredTime: true })
  }

  checkDashboardStatus = async (callback) => {
    const { publicKey } = this.props
    const { repeat, tries } = this.state
    if (repeat && tries <= 4) {
      this.setPreloadStep(tries)
      setTimeout(() => {
        Middleware.getTable(process.env.REACT_APP_MIDDLEWARE_ITEM, 'Patient', null, { PublicKey: publicKey }, this.success, this.error)
      }, 500 * tries * 2)
    } else {
      this.goToNextStep()
    }
  }

  goToNextStep = () => {
    const { newAppointment } = this.state
    const { setAppointment } = this.props
    setAppointment(newAppointment)
  }

  setPreloadStep = (step) => {
    this.setState({ preloadStep: messages.preloadSteps[step] })
  }

  success = async (data) => {
    const { tries } = this.state
    const patient = data.Patient
    if (patient && patient.Dashboard_State) {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      if (params.redirectUrl) {
        const url = new URL(params.redirectUrl)
        const urlSearchSubParams = new URLSearchParams(url.search)
        const subParams = Object.fromEntries(urlSearchSubParams.entries())
        if (subParams.state) {
          if (subParams.state === patient.Dashboard_State) {
            this.setState({ tries: tries + 1 })
            if (tries < 4) {
              await this.checkDashboardStatus()
            } else {
              this.goToNextStep()
            }
          } else {
            this.setState({ repeat: false })
            this.goToNextStep()
          }
        } else {
          this.goToNextStep()
        }
      }
    } else {
      this.goToNextStep()
    }
  }

  error = (data) => {
    console.log(data)
    this.setState({ repeat: false })
  }

  submitData = async () => {
    this.setState({ loader: true })
    const { expiredTime } = this.state
    const {
      dateSelected, slotCalendarId, slotAppointmentTypeId, branch, userData, setAppointment, customerId,
      publicKey, onsite, partner, requirePayment, crmAppointmentId,
      reason, cardHolder, cardCharged, isFree, country
    } = this.props
    const id = branch.Appointment_Type_Id
    if (expiredTime) {
      return ''
    }
    const journey = onsite || false
    let payment = isFree ? 'free_appointment' : cardHolder || cardCharged == 'Holded' ? 'card_holder' : requirePayment ? 'appointment' : false;
    let rescheduleId
    let redirectUrl
    if (this.props && this.props.history.location && this.props.history.location.search) {
      const search = queryString.parse(this.props.history.location.search)
      rescheduleId = search.rescheduleId
      redirectUrl = search.redirectUrl
    }
    const queryParams = paramsObject();
    const { utm_source: utmSource, utm_term: utmTerm } = queryParams;
    let appointment
    if (crmAppointmentId || rescheduleId) {
      // await apiAcuity.update(rescheduleId || crmAppointmentId, { fields: [{ id: process.env.REACT_APP_RESCHEDULING_FIELD_ID, value: 'rescheduling' }] })
      appointment = await apiAppointment.reschedulelAppointment({
        appointmentId: rescheduleId || crmAppointmentId,
        appointmentTypeID: id,
        publicKey: publicKey,
        calendarID: slotCalendarId || branch.Calendar_Id,
        customer_id: customerId,
        datetime: dateSelected,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phone: userData.phone,
        rescheduling: true,
        //payment,
        form: {},
        journey,
        partner,
        appointment: 'retail',
        appointment_reason: "scan",
        utm_source: utmSource,
        utm_term: utmTerm
      })
      // await apiAcuity.cancel(rescheduleId || crmAppointmentId, undefined, utmSource, utmTerm)
    } else {
      if (requirePayment) {
        let data = {
          distinct_id: customerId,
          customer_id: customerId,
          country_ops: country,
          platform: 'appointment_platform_v2'
        };
        setTrackingPatient({ CustomerId: customerId }, { name: 'Old Appointment Platform - Appointment Prescheduled', data: data })
      }

      console.log('slotAppointmentTypeId', slotAppointmentTypeId)
      const calendarId = slotCalendarId || branch.Calendar_Id
      appointment = await apiAppointment.createAppointment({
        appointmentTypeID: slotAppointmentTypeId || id,
        publicKey: publicKey,
        customer_id: customerId,
        calendarID: calendarId,
        datetime: dateSelected,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phone: userData.phone,
        rescheduling: false,
        payment,
        form: {},
        journey,
        partner,
        appointment: 'retail',
        appointment_reason: 'scan',
        utm_source: utmSource,
        utm_term: utmTerm
      })
    }
    /*
    const appointment = await apiAcuity.appointments(
      dateSelected,
      slotAppointmentTypeId || id,
      userData,
      customerId,
      publicKey,
      partner,
      journey,
      payment,
      reason,
      calendarId
    )
    */
    if (appointment && appointment.id) {
      try {
        console.log('ga4_appointment_confirmed')
        window?.dataLayer?.push({ 'event': 'appointment_confirmed' });
      } catch (err) {
        console.log(err)
      }
      setTrackingRudder('appointment_confirmed', { platform: 'new_appointment', country_ops: country, customer_id: customerId, branch_name: branch.Center_Name, datetime: this.props?.dateSelected });
      let history = `${maintainUrlSearch()}`
      history += `&rescheduleId=${appointment.id}`
      if (!requirePayment && this.props.counterPromo) {
        setAppointment && setAppointment(appointment)
        return
      }
      this.setState({ newAppointment: appointment })
      if (redirectUrl) {
        // history += `&redirectUrl=${encodeURIComponent(redirectUrl)}`
        if (!requirePayment) {
          const url = new URL(redirectUrl)
          if (url) {
            const urlSearchSubParams = new URLSearchParams(url.search)
            const subParams = Object.fromEntries(urlSearchSubParams.entries())
            if (subParams.state) {
              this.props.history.push(history)
              this.setPreloadStep(0)
              this.checkDashboardStatus()
            } else {
              setAppointment && setAppointment(appointment)
              this.props.history.push(history)
            }
          }
        } else {
          this.goToNextStep()
        }
      } else {
        this.props.history.push(history)
        setAppointment && setAppointment(appointment)
      }
    } else {
      this.setState({ error: messages.error, loader: false })
    }
  }

  returnPopup = () => {
    const { nextStep } = this.props
    nextStep(true)
  }

  /**
   * Render
   * @returns {void}
   */
  render() {
    const { userData } = this.props
    const { firstName } = userData
    const name = firstName.split(" ")[0];

    const welcome = messages.welcome.replace('{Name}', name)

    return (
      <div className={styles.ContainerAccept}>
        <p className={styles.TitleName}>{welcome}<img className={styles.MoonIcon} src={moon} alt="moon" /></p>

        {this.branchChoose()}

        <TestimonialsCarousel info={testimony} />
      </div>
    )
  }
}

export default Accept
