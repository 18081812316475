const messages = {
  additionalComments: 'Ingresa comentarios adicionales',
  maximumCharacters:  'Máximo 150 caracteres',
  selectOption: 'Selecciona una opción',
  reschedule: {
    title: '¿Estás seguro que deseas reagendar tu cita?',
    description: 'Recuerda que si lo haces no podremos hacerte el reembolso de tu primera cita.',
    descriptionHolder: '¿Estás seguro que deseas reagendar?',
    question: '¿Estás seguro de reagendar tu cita?',
    error: 'No se pudo reagendar tu cita, intenta más tarde',
    confirm: 'Reagendar cita',
    cancel: 'No reagendar',
    reasonTitle: 'Cuéntanos cuál es el motivo para reagendar tu cita',
    reason: 'Ingresa un motivo para reagendar',
    reasonRequired: 'El motivo para reagendar es requerido',
    support: 'Hola necesito ayuda para reagendar mi cita'
  },
  cancel: {
    title: '¿Estás seguro que deseas cancelar tu cita?',
    description: 'Recuerda que si lo haces no podremos hacerte el reembolso de tu primera cita.',
    descriptionHolder: '¿Estás seguro que deseas cancelar?',
    question: '¿Estás seguro de cancelar tu cita?',
    button: 'CANCELAR',
    footer: 'Mantener mi cita',
    confirm: 'Cancelar cita',
    cancel: 'No cancelar cita',
    error: 'No se pudo cancelar tu cita, intenta más tarde',
    reasonTitle: '¿Cuéntanos cuál es el motivo para cancelar tu cita?',
    reason: 'Ingresa un motivo de la cancelación',
    reasonRequired: 'El motivo de la cancelación es requerido',
    checkboxRefund: 'Quiero obtener un reembolso y no continuar con el proceso.',
    messageRefund: '¡Hola,%20quiero%20obtener%20un%20reembolso!',
    support: 'Hola necesito ayuda para cancelar mi cita'
  },
  citaCancelada: {
    title: 'Cita cancelada',
    description0: 'Tu cita fue cancelada de manera exitosa. Para continuar con el proceso, es necesario que elijas nuevamente una fecha y lugar para realizar el escaneo de tu sonrisa',
    description1: 'Tu cita fue cancelada de manera exitosa. Para obtener un reembolso, habla con nuestro equipo de soporte.',
    description2: 'Se realizará un reembolso a tu método de pago seleccionado en un lapso de 20 días. Para más información, consulta nuestra',
    buttonPolicy: 'Política de devolución',
    buttonSupport: 'Hablar con soporte',
    buttonContinue: 'Continuar'
  }
}

export default messages
