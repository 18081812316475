/* eslint-disable react/jsx-no-literals */
import React from 'react'
import I18n from '../../i18n'
import style from './Footer.module.css'
import facebook from '../../Assets/images/svg/facebook.svg'
import youtube from '../../Assets/images/svg/youtube.svg'
import instagram from '../../Assets/images/svg/instagram.svg'
import linkedin from '../../Assets/images/svg/linkedin.svg'

const legalURLs = {
  México: process.env.REACT_APP_LEGAL_URL_MX,
  Colombia: process.env.REACT_APP_LEGAL_URL_CO,
  Peru: process.env.REACT_APP_LEGAL_URL_PE,
}

const socialMediaLinks = {
  facebook: process.env.REACT_APP_FACEBOOK,
  instagram: process.env.REACT_APP_INSTAGRAM,
  youtube: process.env.REACT_APP_YOUTUBE,
  linkedin: process.env.REACT_APP_LINKEDIN,
}

/**
 * Footer Component
 * @returns {void} .
 */
const Footer = ({ country }) => {
  const isMexico = country === 'México'
  return (
    <div className={style.FooterContainer}>
      <div className={style.Container}>
        <div className={style.Left}>
          <span className={style.Copyright}>
            {`© ${new Date().getFullYear()} Moons`}
          </span>
          <a
            className={style.FooterLink}
            rel="noreferrer"
            href={`${legalURLs[country]}terminos-y-condiciones`}
            target="_blank"
          >
            Términos y condiciones
          </a>

          {/* {isMexico ? (
            <a
              className={style.FooterLink}
              rel="noreferrer"
              href={`${legalURLs[country]}terminos-y-condiciones-liverpool`}
              target="_blank"
            >
              Términos y condiciones Liverpool
            </a>
          ) : null} */}
          {/* {isMexico ? (
            <a
              className={style.FooterLink}
              rel="noreferrer"
              href={`${legalURLs[country]}terminos-y-condiciones-dr-moons`}
              target="_blank"
            >
              Términos y condiciones Dr. Moons
            </a>
          ) : null} */}
          <a
            className={style.FooterLink}
            rel="noreferrer"
            href={`${legalURLs[country]}aviso-de-privacidad`}
            target="_blank"
          >
            Aviso de privacidad
          </a>
        </div>
        <div className={style.Right}>
          <a
            target="_blank"
            rel="noreferrer"
            href={socialMediaLinks.facebook}
          >
            <img src={facebook} width="28px" height="28px" alt="Facebook" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={socialMediaLinks.instagram}
          >
            <img src={instagram} width="28px" height="28px" alt="Instagram" />
          </a>
          <a target="_blank" rel="noreferrer" href={socialMediaLinks.youtube}>
            <img src={youtube} width="28px" height="28px" alt="YouTube" />
          </a>
          <a target="_blank" rel="noreferrer" href={socialMediaLinks.linkedin}>
            <img src={linkedin} width="28px" height="28px" alt="LinkedIn" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
