import React from 'react'
import style from './index.module.css'
import Mobile from './mobile/mobile'
import Tablet from './tablet/tablet'

/**
 * Testimonials Carousel Component
 * @return {void}
 */
const TestimonialsCarousel = ({ info }) => (
  <div className={style.CarouselContainer}>
    <Tablet info={info} />
  </div>
)

export default TestimonialsCarousel
