const messages = {
  titleDesktop: 'Selecciona tu centro más cercano, para escanear tu sonrisa 3D ',
  titleMobile: 'Selecciona tu centro más cercano, para escanear tu sonrisa 3D ',
  welcome: '{Name}, aquí inicia tu mejor sonrisa',
  modalTitle: {
    one: 'Agenda tu',
    two: 'videollamada GRATIS'
  },
  modalContent: {
    one: 'El centro que seleccionaste en ',
    // centerName will be replace by the real name of branch (center)
    two: 'centerName es un centro aliado, ',
    three: 'si tienes alguna duda puedes agendar una videollamada con alguno de nuestros expertos para resolver todas tus dudas.'
  },
  modalCTA: 'AGENDAR LLAMADA',
  filterBy: 'Filtrar por:',
  applyFilter: 'Aplicar filtro',
  clearFilter: 'Borrar filtro',
  state: 'Estado',
  city: 'Ciudad',
  center: 'Centro',
  select: 'Seleccionar',
  empty: 'Lo sentimos, no tenemos centros en esta locación, elige otra opción.'
}

export default messages
