/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
import { ErrorException } from '../Utils/error-exception'
import { callApi } from './Request'

const { REACT_APP_APPOINTMENT_API_URL } = process.env

const Methods = {
  GET: 'GET',
  POST: 'POST',
}

/**
 * Create a Basic Authorization Header
 * @returns {Object} {Authorization, Content-Type}
 */
const basicAuthHeader = () => {
  const user = process.env.REACT_APP_APPOINTMENT_API_USERNAME
  const password = process.env.REACT_APP_APPOINTMENT_API_PASSWORD
  const auth = `${user}:${password}`
  const authEncoded = `Basic ${Buffer.from(auth).toString('base64')}`
  return {
    Authorization: authEncoded,
    'Content-Type': 'application/json',
  }
}

/**
 * Call Appointment Api
 *
 * @param {*} path Path to call
 * @param {*} method Method to call
 * @param {*} body Body to send
 * @param {*} success Success function
 * @param {*} error Error function
 * @returns {unknown} return object
 */
const callAppointmentApi = (path, method, body, success, error) => {
  try {
    return callApi(
      `${REACT_APP_APPOINTMENT_API_URL}/${path}`,
      {
        headers: basicAuthHeader(),
        method,
        body: body ? JSON.stringify(body) : null,
      },
      success,
      error
    )
  } catch (err) {
    console.error({ err })
    if (err.message) {
      throw new ErrorException(
        err.message,
        err.name,
        err.object,
      )
    } else {
      throw new ErrorException(
        'Error general in function apiAppointment',
        'apiAppointment',
        { err },
      )
    }
  }
}

/**
 * Description placeholder
 *
 * @type {{ getSmileCenters(country: any, service: any, success: any, error: any): unknown;
 * getSlots(calendarId: any, appointmentTypeId: any, success: any, error: any): unknown;
 * createAppointment(data: any, success: any, error: any): unknown;
 * cancelAppointment(data: any, success: any, error: any): unknown;
 * reschedulelAppointment(data: any, success: any, error: any): unknown;}
 */
export const apiAppointment = {

  /**
   * Get Smile Centers
   *
   * @param {*} country Country of the smile centers
   * @param {*} service Services of the smile centers
   * @param {*} success Success function
   * @param {*} error Error function
   * @returns {unknown} return response
   */
  getSmileCenters(
    country,
    service,
    success,
    error
  ) {
    let params = `country=${country}`
    if (service) {
      params += `&services=${service}`
    }
    return callAppointmentApi(`get-centers?${params}`, Methods.GET, null, success, error)
  },

  /**
   * Get time slots by calendarId and appointmentTypeId
   *
   * @param {*} calendarId Calendar Id
   * @param {*} appointmentTypeId Appointment Type Id
   * @param {*} page Page number
   * @param {*} success Success function
   * @param {*} error Error function
   * @returns {unknown} return response
   */
  getSlots(
    calendarId,
    appointmentTypeId,
    page,
    success,
    error
  ) {
    return callAppointmentApi(`get-slots?appointmentTypeId=${appointmentTypeId}&calendarId=${calendarId}&limit=15&page=${page}`, Methods.GET, null, success, error)
  },

  /**
   * Create an appointment
   *
   * @param {*} data Appointment data
   * @param {*} success Success function
   * @param {*} error Error function
   * @returns {unknown} return response
   */
  createAppointment(
    data,
    success,
    error
  ) {
    return callAppointmentApi('appointment', Methods.POST, data, success, error)
  },

  /**
   * Cancel an appointment
   *
   * @param {*} data Appointment data
   * @param {*} success Success function
   * @param {*} error Error function
   * @returns {unknown} return response
   */
  cancelAppointment(
    data,
    success,
    error
  ) {
    return callAppointmentApi('cancel/appointment', Methods.POST, data, success, error)
  },

  /**
   * Reschedule an appointment
   *
   * @param {*} data Appointment data
   * @param {*} success Success function
   * @param {*} error Error function
   * @returns {unknown} return response
   */
  reschedulelAppointment(
    data,
    success,
    error
  ) {
    return callAppointmentApi('reschedule/appointment', Methods.POST, data, success, error)
  },
}
