/* eslint-disable global-require */
const data = [
  {
    id: 0,
    name: 'Marcos Escobedo',
    source: 'Reseña de Google Maps',
    description: '"Una experiencia muy buena, atención inmediata. Los doctores son muy atentos y eficaces. Lo recomiendo, excelente servicio".',
    image: require('../Assets/images/png/testimony1.png')
  },
  {
    id: 1,
    name: 'Darian Rojas',
    source: 'Reseña de Google Maps',
    description: '"Los doctores que me atendieron fueron muy amables. Me sentí muy cómoda en el consultorio y me explicaron todo a detalle”.',
    image: require('../Assets/images/png/testimony2.png')
  },
  {
    id: 2,
    name: 'Anders Wu',
    source: 'Reseña de Google Maps',
    description: '"Los Moons son excelentes, de muy buena calidad y la atención al cliente es súper buena”.',
    image: require('../Assets/images/png/testimony3.png')
  }
]

export default data
