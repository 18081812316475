import React from 'react'
import 'moment/locale/es'
import styles from './Slider.module.css'
import start from '../../../Assets/images/svg/star.svg'
import okBlue from '../../../Assets/images/svg/okBlue.svg'

/**
 * Slider Component
 * @return {JSX.Element}
 */
const Slider = ({ item }) => {
  const starts = [1,
    2,
    3,
    4,
    5]
  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <div className={styles.Starts}>
          {starts.map(st => (
            <img
              key={st}
              alt="start"
              src={start}
              className={styles.ItemStart}
            />
          ))}
        </div>
        <p className={styles.Description}>{item.description}</p>
        <div className={styles.ContainerBody}>
          <div className={styles.ContainerBody}>

            <img
              alt="slide"
              src={item.image.default}
              className={styles.Images}
            />
            <div className={styles.ContainerName}>
              <p className={styles.Name}>{item.name}</p>
              <p className={styles.Source}>{item.source}</p>
            </div> 
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slider
