const messages = {
  back: 'Cambiar fecha y hora',
  buttonText: 'Confirmar cita',
  confirmAppointment: 'Confirmar cita',
  confirmAppointmentFree: 'Confirmar cita gratis',
  payAppointment: 'Pagar cita',
  freeAppointment: 'Agendar cita gratis',
  rescheduleAppointment: 'Reagendar cita',
  error: 'Hubo un error al crear la cita, intenta más tarde',
  welcome: '{Name}, la sonrisa de tus sueños esta cerca',
  preloadSteps: [
    'Revisando fecha y horario',
    'Fecha y horario confirmada',
    'Agendando cita',
    'Cita agendada',
    'Programando recordatorios',
    'Recordatorios programados',
    'Generando página con los detalles de la cita'
  ],
  info: 'Información de tu próxima cita',
}

export default messages
