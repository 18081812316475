import React, { Component } from 'react'
import moment from 'moment'
import Cookies from 'js-cookie'
import style from './TimeCounter.module.css'
import messages from './TimeCounter.messages'
import FullModal from '../FullModal/FullModal'
import timerIcon from '../../Assets/images/svg/timer-alert.svg'
import { Button } from '@mymoons/ui-library'
import {
  FREE_APPOINMENT_VARIANT_MEDELLIN,
  FREE_APPOINMENT_VARIANT_BUCARAMANGA,
} from '../../Utils/constants'

/**
 * TimeCounter Component
 * @returns {void} .
 */
class TimeCounter extends Component {
  /**
   * TimeCounter constructor
   * @returns {void} .
   */
  constructor(props) {
    super()
    const freeAppointmentConditional =
      ((props.promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN ||
        props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA) &&
      props.counterPromo)
    this.state = {
      time: {
        minutes: freeAppointmentConditional ? props.syncMinutes : '10',
        seconds: freeAppointmentConditional ? props.syncSeconds : '00',
        expired: false,
      },
      timeExpired: false,
      showModal: false,
    };
  }

  /**
   * Component Did Mount
   * @returns {void} .
   */
  componentDidMount() {
    this.countDown()
  }

  /**
   * Component Will Unmount
   * @returns {void} .
   */
  componentWillUnmount() {
    this.interval && clearInterval(this.interval)
  }

  freeApppoinmentTest = () => {
    const diff = 0
    const second = 1000
    const { time } = this.state
    const { setExpired } = this.props
    const countDownDate = moment().add(time.minutes, 'minute').add(1, 'seconds')
    this.interval = setInterval(() => {
      const now = moment()
      const distance = countDownDate - now
      const cookieTimer = parseInt(Cookies.get('timer'))
      const difference = Number(new Date(cookieTimer)) - Number(new Date())



      let minutes = Math.floor((difference / 1000 / 60) % 60)
      let seconds = Math.floor((difference / 1000) % 60)
      if (minutes.toString().length === 1) {
        minutes = `0${minutes}`
      }
      if (seconds.toString().length === 1) {
        seconds = `0${seconds}`
      }
      const currentTime = {
        minutes,
        seconds
      }     
      this.setState({ time: currentTime })
     

      if (difference < diff) {
        clearInterval(this.interval)
        const timeExpired = {
          expired: true,
          minutes: '00',
          seconds: '00'
        }
        this.setState({ time: timeExpired, timeExpired: true, showModal: true })
        setExpired && setExpired()
      }
    }, second)
   
  }

  countDown = () => {
    const diff = 0
    const second = 1000
    const { time } = this.state
    const { setExpired } = this.props
    const countDownDate = moment().add(time.minutes, 'minute').add(1, 'seconds')
    // Update the count down every 1 second
    if((this.props.promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN ||
      this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA) &&
    this.props.counterPromo){
      this.freeApppoinmentTest()

    }else{
      this.interval = setInterval(() => {
        const now = moment()
        const distance = countDownDate - now
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        let seconds = Math.floor((distance % (1000 * 60)) / 1000)
        if (minutes.toString().length === 1) {
          minutes = `0${minutes}`
        }
        if (seconds.toString().length === 1) {
          seconds = `0${seconds}`
        }
  
        const currentTime = {
          minutes,
          seconds
        }
       
        this.setState({ time: currentTime })
       
  
        if (distance < diff) {
          clearInterval(this.interval)
          const timeExpired = {
            expired: true,
            minutes: '00',
            seconds: '00'
          }
          this.setState({ time: timeExpired, timeExpired: true, showModal: true })
          setExpired && setExpired()
        }
      }, second)
     
    }
   
  }

  clearPopupTimer = () => {
    const { clearPopup } = this.props
    this.setState({ showModal: false })
    clearPopup()
  }

  /**
   * TimeCounter render
   * @returns {void} .
   */
  render() {
    const { time, timeExpired, showModal } = this.state
    const { minutes, seconds } = time
    const timeMinute = `${minutes}:${seconds}`
    const title = messages.advice

    const popupTime = (
      <div className={style.PopupCard}>
        <img
          src={timerIcon}
          alt="alert-icon-timer"
          className={style.TimerIconAlert}
        />
        <div className={style.PopupTitle}>{messages.popupTitle}</div>
        <div className={style.PopupDesc}>{messages.popupDesc}</div>
        <Button
          id="btn-timer"
          type="button"
          size="medium"
          color="red"
          fullWidth={false}
          className={style.PopupBtn}
          label={messages.popupBtn}
          onClick={this.clearPopupTimer}
                    />
      </div>
    )

    return (
      timeExpired ? (
        <FullModal
          open={showModal}
          content={popupTime}
        />
      ) : (
        <div className={[style.CounterContainer, timeExpired ? style.TimeExpired : ''].join(' ')}>
          <p>{title}</p>
          {!timeExpired && <p className={style.Counter}>{timeMinute}</p>}
        </div>
      )
    )
  }
}

export default TimeCounter
