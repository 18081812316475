import React from 'react'
import {
  Switch,
  Route,
  BrowserRouter
} from 'react-router-dom'
import ErrorPage from '@mymoons/error-page'
import Appointment from './Components/Appointment/Appointment'

/**
 * Get whatsapp number
 *
 * @returns {string}
 */
const getWhatsappNumber = () => {
  let whatsappNumber = '525571000303';
  try {
    if (window.location.hostname.includes('.co')) {
      whatsappNumber = `${process.env.REACT_APP_WHATSAPP_CO ? '57'.concat(process.env.REACT_APP_WHATSAPP_CO.replaceAll(' ', '')) : whatsappNumber}`
    } else if (window.location.hostname.includes('.pe')) {
      whatsappNumber = `${process.env.REACT_APP_WHATSAPP_PE ? '51'.concat(process.env.REACT_APP_WHATSAPP_PE.replaceAll(' ', '')) : whatsappNumber}`
    } else {
      whatsappNumber = `${process.env.REACT_APP_WHATSAPP_MX ? '52'.concat(process.env.REACT_APP_WHATSAPP_MX.replaceAll(' ', '')) : whatsappNumber}`
    }
  } catch (e) {
    console.error(e);
  }
  return whatsappNumber;
}

/**
 * Main app
 * @returns {void} .
 */
const App = () => (
  <BrowserRouter>
    <Switch>
      <Route
        path="/appointment-success/:publicKey"
        component={() => (
          <Appointment />
        )}
      />
      <Route
        path="/:publicKey"
        component={() => (
          <Appointment />
        )}
      />
       <Route
        path="/"
        component={() => (
          <Appointment />
        )}
      />
      <Route
        component={() => (<ErrorPage whatsappNumber={getWhatsappNumber()}/>)}
      />
    </Switch>
  </BrowserRouter>
)

export default App
