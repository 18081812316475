/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable complexity */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react'
import { Button, Dropdown } from '@mymoons/ui-library'
import { joiResolver } from '@hookform/resolvers/joi'
import Joi from 'joi'
import { useForm, Controller } from 'react-hook-form'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import styles from './Confirm.module.css'
import messages from './Confirm.messages'
import moon from '../../Assets/images/svg/moon.svg'
import close from '../../Assets/images/svg/close.svg'
import LoaderSection from '../LoaderSection/LoaderSection'
import Middleware from '../../Api/Middleware'
import I18n from '../../i18n'
import maintainUrlSearch from '../../Utils/maintain-url-search'

/**
 * Get Scheme.
 * @param {*} type .
 * @returns {Object} .
 */
const getScheme = (type) => Joi.object({
  reasonDropdown: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
        case 'string.empty':
          err.message = messages[type].reason
          break
        case 'any.empty':
          err.message = messages[type].reason
          break
        case 'any.required':
          err.message = messages[type].reasonRequired
          break
        default:
          break
        }
      })
      return errors
    }),
  additionalComments: Joi.string()
    .allow(null, '')
    .max(150)
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
        case 'any.empty':
          err.message = messages[type].reasonRequired
          break
        case 'string.empty':
          err.message = messages.additionalComments
          break
        default:
          break
        }
      })
      return errors
    })
})

const optionsCancel = [
  { id: 'initial', value: '', label: 'Selecciona una opción' },
  { id: '1', value: 'Moving out', label: 'Cambio de residencia' },
  { id: '2', value: 'Disease', label: 'Temas de salud' },
  { id: '3', value: 'Treatment cost', label: 'Costo de tratamiento' },
  { id: '4', value: 'Reduced income', label: 'Reducción de ingresos' },
  { id: '5', value: 'Lack of time', label: 'Falta de disponibilidad en agenda' },
  { id: '6', value: 'Distrust', label: 'Desconfianza' },
  { id: '7', value: 'Another treatment ongoing', label: 'Ya cuento con otro tratamiento de alineación' },
  { id: '8', value: 'By Moons', label: 'Por Moons' },
]

const optionsReschedule = [
  { id: 'initial', value: '', label: 'Selecciona una opción' },
  { id: '1', value: 'Disease', label: 'Temas de salud' },
  { id: '2', value: 'Working hours', label: 'Horario laboral' },
  { id: '3', value: 'I forgot that I had scheduled', label: 'Se me olvidó que tenía cita' },
  { id: '4', value: 'Pending treatments', label: 'Tratamientos pendientes' },
  { id: '5', value: 'I won\'t be in town', label: 'Viaje' },
  { id: '6', value: 'Personal reasons', label: 'Motivos personales' },
  { id: '7', value: 'By Moons', label: 'Por Moons' },
]

/**
 * Confirm component.
 * @returns {void} .
 */
const Confirm = ({
  type, branch, dateSelected, userData, showModal, cancelAppointment, nextStep, timeZone, history, setReason, customerId,
  country, publicKey, journeyVariant
}) => {
  const [loader, setLoader] = useState(false)
  const [error, setError] = useState(false)
  const [isOpenReschedulerModal, setIsOpenReschedulerModal] = useState(false)
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)
  const [isOpenCancelConfirmationModal, setIsOpenCancelConfirmationModal] = useState(false)
  const [reasonDropdown, setReasonDropdown] = useState()
  const [isShowComments, setIsShowComments] = useState(false)
  const [disabledBtn, setDisableBtn] = useState(true)
  const [errorComments, setErrorComments] = useState()
  const [refund, setRefund] = useState(false)
  const [repeat, setRepeat] = useState(true)

  let tries = 1

  const returnPolicyUrl = country === 'México' ? process.env.REACT_APP_RETURN_POLICY_URL_MX
    : country === 'Colombia' ? process.env.REACT_APP_RETURN_POLICY_URL_CO
      : country === 'Chile' ? process.env.REACT_APP_RETURN_POLICY_URL_CL
        : process.env.REACT_APP_RETURN_POLICY_URL_PE
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: joiResolver(getScheme(type)),
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  /**
   * Form Submit.
   * @param {*} data .
   * @returns {void} .
   */
  const onFormSubmit = (data) => {
    const reasonParams = {
      customerId,
      reason: data.reasonDropdown
    }
    if (data.reasonDropdown === 'Other') {
      if (isEmpty(data.additionalComments)) {
        setErrorComments(messages.additionalComments)
        return
      }
      reasonParams.otherReason = data.additionalComments
    }
    setReason(reasonParams)
    if (type === 'reschedule') {
      reschedule()
    } else {
      cancel()
    }
  }

  /**
   * Cancel.
   * @param {Function} setLoader .
   * @returns {void} .
   */
  const cancel = async () => {
    setDisableBtn(true)
    setLoader(true)
    const response = await cancelAppointment()
    if (!response || response.code) {
      const errorText = response.message || messages[type].error
      setError(errorText)
      setDisableBtn(false)
      setLoader(false)
    } else {
      checkAppointmentStatus()
    }
  }

  /**
   * Redirect Support.
   * @returns {void} .
   */
  const redirectSupport = () => {
    const { country } = userData
    if (country === 'México') {
      window.open(
        `https://api.whatsapp.com/send?phone=525571000303&text=${messages[type].messageRefund}`,
        '_self'
      )
    } else {
      window.open(
        `https://api.whatsapp.com/send?phone=574899021&text=${messages[type].messageRefund}`,
        '_self'
      )
    }
  }

  /**
   * Check Appointment Status.
   * @returns {void} .
   */
  const checkAppointmentStatus = () => {
    if (repeat && tries <= 3) {
      setTimeout(() => {
        Middleware.getTable(process.env.REACT_APP_MIDDLEWARE_ITEM, 'Patient', null, { PublicKey: publicKey }, Success, Error)
      }, 1000 * tries * 2)
    } else {
      setLoader(false)
      setIsOpenCancelConfirmationModal(true)
    }
  }

  /**
   * Success callback.
   * @param {*} data .
   * @returns {void} .
   */
  const Success = (data) => {
    const patient = data.Patient
    if (patient && patient.Appointment_Status) {
      if (patient.Appointment_Status === 'Canceled') {
        setLoader(false)
        setRepeat(false)
        setIsOpenCancelConfirmationModal(true)
      } else {
        tries++
        if (tries <= 3) {
          checkAppointmentStatus()
        } else {
          setLoader(false)
          setRepeat(false)
          setIsOpenCancelConfirmationModal(true)
        }
      }
    } else {
      setLoader(false)
      setRepeat(false)
      setIsOpenCancelConfirmationModal(true)
    }
  }

  /**
   * Error callback.
   * @param {*} data .
   * @returns {void} .
   */
  const Error = (data) => {
    console.log(data)
    setRepeat(false)
  }

  /**
   * Redirect.
   * @returns {void} .
   */
  const redirect = () => {
    let redirectUrl
    let search
    if (history && history.location && history.location.search) {
      search = queryString.parse(history.location.search)
      redirectUrl = search.redirectUrl
      if (redirectUrl) {
        history.push(`?redirectUrl=${encodeURIComponent(redirectUrl)}`)
      } else {
        history.push(maintainUrlSearch())
      }
    }
    if (redirectUrl) {
      window.open(redirectUrl, '_self')
    } else {
      window.location.reload()
    }
  }

  /**
   * Reschedule.
   * @param {Function} setLoader .
   * @returns {void} .
   */
  const reschedule = async () => {
    const goToTheSpecificStepReschedule = true
    const isBack = false
    nextStep(isBack, goToTheSpecificStepReschedule)
  }

  const i18n = new I18n(country)

  return (
    <div className={styles.ConfirmContainer}>
      {isOpenCancelConfirmationModal && (
        <div className={styles.PopUp}>
          <div className={styles.WrapFull}>
            <div className={styles.Content}>
              <img className={styles.MoonIcon} alt="icon" src={moon} />
              <p className={styles.TitleCancel}>
                {messages.citaCancelada.title}
              </p>
              <p className={styles.Desc} style={{ textAlign: 'center' }}>
                {refund ? messages.citaCancelada.description1 : messages.citaCancelada.description0}
              </p>
              {refund && (
                <>
                  <p className={styles.DescSmall} style={{ textAlign: 'center' }}>
                    {messages.citaCancelada.description2}
                  </p>
                  <div className={styles.ModalCancelConfirmPolicy}>
                    <Button
                      color="red"
                      label={messages.citaCancelada.buttonPolicy}
                      href={returnPolicyUrl}
                      target="_blank"
                      size="medium"
                      fullWidth
                    />
                  </div>
                </>
              )}
              <div className={styles.ModalCancelConfirmSupport}>
                <Button
                  color="red"
                  label={refund ? messages.citaCancelada.buttonSupport : messages.citaCancelada.buttonContinue}
                  onClick={() => (refund ? redirectSupport() : redirect())}
                  size="medium"
                  variant="filled"
                  fullWidth
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {!isOpenCancelConfirmationModal && (
        <div className={styles.PopUp}>
          <div className={styles.WrapHeader}>
            <p className={styles.Title}>
              {messages[type].title}
            </p>
            <img src={close} alt="cerrar" className={styles.CloseIcon} onClick={() => showModal()} />
          </div>
          <div className={styles.Wrap}>
            <div className={styles.Content}>
              <p className={styles.Desc}>
                {journeyVariant === 'card_holder'
                  ? messages[type].descriptionHolder
                  : messages[type].description}
              </p>
              <div
                className="info-modal"
                style={{ minHeight: 'initial', maxWidth: 664, width: '100%' }}
              >
                <div className="info-modal-container">
                  <form onSubmit={handleSubmit(onFormSubmit)} className="fullwidth" noValidate>
                    <div className={styles.SelectContainer}>
                      <p className={styles.SelectTitle}>
                        {messages[type].reasonTitle}
                      </p>
                      <Dropdown
                        name="reasonDropdown"
                        fullWidth
                        error={errors?.reasonDropdown?.message}
                        label=""
                        placeholder={messages.selectOption}
                        value={reasonDropdown}
                        onChange={(e) => {
                          setIsShowComments(e.value == 'Other')
                          setReasonDropdown(e)
                          setValue('reasonDropdown', e.value)
                          setDisableBtn(!e.value)
                        }}
                        options={type === 'reschedule' ? optionsReschedule : optionsCancel}
                      />

                      {isShowComments && (
                        <>
                          <label className={styles.AdditionalCommentsLabel}>Comentarios adicionales</label>
                          <Controller
                            name="additionalComments"
                            control={control}
                            render={({ field: { onChange, value, name } }) => (
                              <textarea
                                className={styles.AdditionalComments}
                                name={name}
                                label={messages.additionalComments}
                                placeholder={messages.maximumCharacters}
                                variant="outline"
                                value={value}
                                onChange={onChange}
                                maxLength={150}
                                pattern="(.|\n)*?"
                              />
                            )}
                          />
                          <p className={styles.ErrorComments}>{errorComments}</p>
                        </>
                      )}
                    </div>
                    {loader ? (
                      <div className={styles.ContainerLoader}>
                        <LoaderSection />
                      </div>
                    ) : (
                      <div className={styles.ModalRightButtons}>
                        <Button
                          color="red"
                          label={messages[type].cancel}
                          onClick={() => showModal()}
                          size="medium"
                          fullWidth
                          variant="outline"
                        />
                        <Button
                          isButton
                          type="submit"
                          color="red"
                          label={messages[type].confirm}
                          size="medium"
                          variant="filled"
                          fullWidth
                          disabled={disabledBtn}
                        />

                      </div>
                    )}
                    {error && <p className={styles.Error}>{error}</p>}
                  </form>
                  {/* <a
                    className={styles.SupportButton}
                    target="_blank"
                    rel="noreferrer"
                    href={`${i18n.msg.socialUrl.whatsappSupport}&text=${messages[type].support}`}
                  >
                    Contactar a soporte
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Confirm
