/* eslint-disable max-statements */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import React, { createRef } from 'react'
import moment from 'moment'
import 'moment/locale/es'
import { Button, PageLoader } from '@mymoons/ui-library'
import queryString from 'query-string'
import isEmpty from 'lodash/isEmpty'
import styles from './ChooseDates.module.css'
import messages from './ChooseDates.messages'
import Utils from '../../Utils/utils'
import addressIcon from '../../Assets/images/svg/address.svg'
import discount from '../../Assets/images/svg/discount.svg'
import arrow from '../../Assets/images/svg/chevronLeft.svg'
import right from '../../Assets/images/svg/chevronRight.svg'
import leftGray from '../../Assets/images/svg/leftGray.svg'
import rightGray from '../../Assets/images/svg/rightGray.svg'
import moonGray from '../../Assets/images/svg/moon_gray.svg'
import {
  FREE_APPOINMENT_VARIANT_MEDELLIN,
  FREE_APPOINMENT_VARIANT_BUCARAMANGA,
  PROMO_APPOINMENT_VARIANT_MEDELLIN,
  PROMO_APPOINMENT_VARIANT_BUCARAMANGA
} from '../../Utils/constants'
import { setTrackingPatient } from '../../Utils/tracking'
import FullModal from '../FullModal/FullModal'
import { apiBlackBox } from '../../Api/BlackBox'
import { apiAppointment } from '../../Api/Appointment'
import calendar from '../../Assets/images/png/calendar.png'
import checked from '../../Assets/images/svg/checked.svg'

const DESKTOP_WIDTH = 860
const MAX_SLOTS_PER_PAGE = 15

/**
 * ChooseDates Component
 * @return {void}
 */
class ChooseDates extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    let search = {}
    if (props && props.history.location && props.history.location.search) {
      search = queryString.parse(props.history.location.search)
      if (search.state) {
        delete search.state
      }
    }
    props.history.push(`?${new URLSearchParams(search).toString()}`)
    this.wrapperRef = createRef();
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);

    this.state = {
      page: 1,
      isLimit: false,
      dates: [],
      labelDates: {},
      loadingTimes: false,
      loader: true,
      centerDate: 1,
      times: [],
      enablePriorityBooking: false,
      showPriorityBooking: false,
      priorityBookingCta: false,
      services: props?.services || false,
      modal: {
        showModal: false,
        content: null
      },
      startX: 0,
      startY: 0
    }
  }
  

  /**
   * Component Did Mount
   * @return {void}
   */
  componentDidMount = () => {
    this.calculateDates()
    const { patient } = this.props
    if (patient.Country_Ops === 'México') {
      this.setState({ enablePriorityBooking: true })
    }
    const disablePriorityBooking = patient?.Priority_Booking === 'To Be Contacted' || patient?.Priority_Booking === 'Contacted'
    this.setState({ priorityBookingCta: disablePriorityBooking })
    document.addEventListener('touchstart', this.handleTouchStart);
    document.addEventListener('touchend', this.handleTouchEnd);
    window.scrollTo(0, 0);
  }

  componentWillUnmount(){
    document.removeEventListener('touchstart', this.handleTouchStart);
    document.removeEventListener('touchend', this.handleTouchEnd);
  }

  handleTouchStart(e){
    //e.preventDefault()
    if (!this.wrapperRef || !this.wrapperRef.current.contains(e.target)) {
      return
    }
    this.setState({ startX: e.touches[0].clientX, startY: e.touches[0].clientY })
  }

  handleTouchEnd(e){
    //e.preventDefault()
    if (!this.wrapperRef || !this.wrapperRef.current.contains(e.target)) {
      return
    }    
    const endX = e.changedTouches[0].clientX
    const endY = e.changedTouches[0].clientY
    const deltaX = endX - this.state.startX
    const deltaY = endY - this.state.startY
    
    this.handleSwipe({ deltaX, deltaY })
  }

  handleSwipe({ deltaX, deltaY }){
    if (Math.abs(deltaX) > Math.abs(deltaY)) {
          if (deltaX > 0) {
            this.changeDate('left')
          } else {
            this.changeDate('right')
          }
      }
  }

  /**
   * Calculate dates
   * @return {void}
   */
  calculateDates = async () => {
    const {
      page, times, services, centerDate
    } = this.state
    const { branch } = this.props
    const id = branch && branch.Appointment_Type_Id
    const calendarId = branch?.Calendar_Id || false

    /**
     * Success callback
     * @param {*} data Response data
     * @returns {void}
     */
    const success = (data) => {
      if (data && data.length > 0) {
        let dates = data
        dates = dates.map((item) => {
          item.calendarId = calendarId
          item.appointmentTypeId = branch?.Appointment_Type_Id
          return item
        })
        const { labelDates, newDates } = this.mapDates(times, dates)
        this.setState({
          dates: newDates,
          labelDates,
          times: [...times, ...data],
          loadingTimes: false,
          loader: false,
          page: page + 1,
          isLimit: data.length < MAX_SLOTS_PER_PAGE || page + 1 > (services ? 6 : 2),
          centerDate: centerDate + (page > 1 ? 1 : 0)
        })
      } else {
        this.setState({
          loadingTimes: false,
          loader: false,
          showPriorityBooking: true,
          isLimit: true
        })
      }
    }

    /**
     * Failure callback
     * @param {*} err Error data
     * @returns {void}
     */
    const failure = (err) => {
      console.log(err)
      this.setState({ loadingTimes: false, loader: false })
    }
    this.setState({ loadingTimes: true })
    apiAppointment.getSlots(calendarId, id, page, success, failure)
  }

  /**
   * Map Dates
   * @param {Array} dates .
   * @param {Array} nextDate .
   * @return {void}
   */
  mapDates = (dates, nextDate) => {
    if (dates.length < 1 && nextDate.length < 1) {
      return { newDates: false, labelDates: [] }
    }

    const tempDate = dates.concat(nextDate)
    while (tempDate.length > 101) {
      tempDate.pop()
    }

    const labelDates = []
    Array.isArray(tempDate) && tempDate.map(item => {
      const temp = moment(item.date).format('dddd DD MMMM')
      if (!labelDates?.includes(temp)) {
        labelDates.push(temp)
      }
    })

    const newDates = Array.isArray(tempDate) && tempDate.map(item => item)
    return { newDates, labelDates }
  }

  dateToAcuity = () => {
    const month = moment().format('YYYY-MM')
    const nextMonth = moment().add(1, 'M').format('YYYY-MM')
    const nextMonth2 = moment().add(2, 'M').format('YYYY-MM')
    const nextMonth3 = moment().add(3, 'M').format('YYYY-MM')
    return {
      month, nextMonth, nextMonth2, nextMonth3
    }
  }

  itemChoose = () => {
    const { branch } = this.props
    const {
      Center_Name, Street, Number, Neighborhood
    } = branch
    const address = Street && Number && Neighborhood ? `${Street} ${Number}, ${Neighborhood}` : ''
    const { welcome } = messages

    return (
      <div
        className={styles.CenterContainer}
        key={branch.Appointment_Type_Id}
      >
        <p className={styles.TitleName}>{messages?.welcome}</p>

        {this.props.promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA || this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_BUCARAMANGA
          ? (
            <>
              <div className={`${styles.AddressContainer} ${styles.promoAppointmentContainer}`}>
                <div className={styles.promoAppointmentTitleContainer}>
                  <p className={styles.Center}>{Center_Name}</p>
                  <div className={styles.promoAppointmentTextContainer}>
                    {(this.props.promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA) && this.props.counterPromo
                      ? (
                        <>
                          <span />
                          <p className={styles.promoAppointmentText}>CITA GRATIS</p>
                        </>
                      )
                      : (
                        <>
                          <span>   </span>
                          <p>PROMO CITA</p>
                        </>
                      )}
                  </div>
                </div>
                {address
                && (
                  <div className={styles.IconContainer}>
                    <img
                      className={styles.IconAddress}
                      alt="icon"
                      src={addressIcon}
                    />
                    <p className={styles.Address}>{address}</p>
                  </div>
                )}
              </div>
            </>
          )
          : (
            <div className={styles.AddressContainer}>
              <p className={styles.Center}>
                {Center_Name}
                {Center_Name.includes('Polanco111') ? <img className={styles.discount} alt="icon" src={discount} /> : null}
              </p>
              {address
                && (
                  <div className={styles.IconContainer}>
                    <img
                      className={styles.IconAddress}
                      alt="icon"
                      src={addressIcon}
                    />
                    <p className={styles.Address}>{address}</p>
                  </div>
                )}
            </div>
          )}
      </div>
    )
  }

  backComponent = () => {
    const { nextStep } = this.props
    return (
      <div className={styles.BackContainer}>
        <div
          role="button"
          tabIndex={0}
          className={styles.Back}
          onClick={() => nextStep(true)}
        >
          <img
            alt="logo"
            src={arrow}
          />
          <p>{messages.back}</p>
        </div>
      </div>
    )
  }

  successSubmit = () => {
    this.setState({
      modal: {
        showModal: false,
      }
    })
  }

  contactSubmit = async () => {
    const { patient, branch } = this.props
    const data = {
      distinct_id: patient.CustomerId,
      customer_id: patient.CustomerId,
      country_ops: patient.Country_Ops,
      name: patient.Patient_Name,
      email: patient.Email,
      phone: patient.Phone,
      center_name: branch.Center_Name,
      platform: 'appointment_platform_v2',
    }
    setTrackingPatient(patient, { name: 'Old Appointment Platform - Priority Booking Confirmed', data })
    this.setState({ modal: { showModal: true } })
    const customerId = patient.CustomerId
    const response = await apiBlackBox.Patient(
      customerId
    )

    if (response.success) {
      this.setState({ priorityBookingCta: true })
      this.setState({
        modal: {
          showModal: true,
          content: this.BookingSuccessContent
        }
      })
    } else {
      this.setState({
        modal: {
          showModal: true,
          content: this.errorBookingContent
        }
      })
    }
  }

  priorityBookingContent = (
    <div className={styles.PopupCard}>
      <img src={calendar} alt="calendar" />
      <div className={styles.PopupTitle}>{messages.priorityBookingTitle}</div>
      <div className={styles.PopupDesc}>{messages.priorityBookingDescription}</div>
      <Button
        type="button"
        size="medium"
        color="red"
        onClick={() => this.contactSubmit()}
        label={messages.priorityBookingCta}
        className={styles.PopupBtn}
      />

      <span onClick={() => this.setState({ modal: { showModal: false } })} className={styles.PopupLink}>{messages.priorityBookingCta2}</span>
    </div>
  )

  BookingSuccessContent = (
    <div className={styles.PopupCard}>
      <img src={checked} alt="check" />
      <div className={styles.PopupTitle}>{messages.bookingSuccessTitle}</div>
      <div className={styles.PopupDesc}>{messages.bookingSuccessDescription}</div>
      <Button
        type="button"
        size="medium"
        color="red"
        onClick={() => this.successSubmit()}
        label={messages.bookingSuccessCta}
        className={styles.PopupBtn}
      />
    </div>
  )

  errorBookingContent = (
    <div className={styles.PopupCard}>
      <div className={styles.PopupTitle}>{messages.bookingErrorTitle}</div>
      <div className={styles.PopupDesc}>{messages.bookingErrorDescription}</div>
      <Button
        type="button"
        size="medium"
        color="red"
        onClick={() => this.contactSubmit()}
        label={messages.bookingErrorCta}
        className={styles.PopupBtn}
      />
      <span
        onClick={() => this.setState({ modal: { showModal: false } })}
        className={styles.PopupLink}
      >
        {messages.bookingErrorCta2}
      </span>
    </div>
  )

  contactMe = () => {
    const { patient, branch } = this.props
    const data = {
      distinct_id: patient.CustomerId,
      customer_id: patient.CustomerId,
      country_ops: patient.Country_Ops,
      name: patient.Patient_Name,
      email: patient.Email,
      phone: patient.Phone,
      center_name: branch.Center_Name,
      platform: 'appointment_platform_v2',
    }
    setTrackingPatient(patient, { name: 'Old Appointment Platform - Priority Booking Required', data })

    this.setState({
      modal: {
        showModal: true,
        content: this.priorityBookingContent
      }
    })
  }

  containerMobile = () => {
    const { times, labelDates, centerDate } = this.state
    const { patient, branch } = this.props
    const country = patient.Country_Ops
    const now = moment().format('dddd DD MMMM')
    const appointmentTypeId = branch && branch.Appointment_Type_Id
      ? branch.Appointment_Type_Id : false

    return (
      <>
        {this.state.showPriorityBooking
          ? (
            <div className={styles.ContainerMobile}>
              <div className={styles.Item}>
                <p className={styles.Label} />
                <p className={styles.Label2} />
                <div className={styles.bookingPriorityContainer}>
                  <div className={styles.bookingTitle}>{messages.bookingTitle}</div>
                  <p className={styles.bookingDescription}>
                    {messages.bookingDescription}
                  </p>
                  <Button
                    type="button"
                    disabled={this.state.priorityBookingCta}
                    size="medium"
                    color="red"
                    label={messages.bookingCta}
                    href="https://wa.me/525571000303?text=Hola%2C%20necesito%20ayuda%20para%20encontrar%20horario%20para%20mi%20cita%20Moons%20"
                    target="_blank"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              className={styles.ContainerMobile}
            >
              <div className={styles.Item}>
                {Array.isArray(times) && Array.isArray(times[centerDate - 1]?.times)
                  && times[centerDate - 1]?.times.length > 0
                  ? (
                    <>
                      <p className={styles.Label}>{this.getLabelDate(labelDates[centerDate - 1] || now, true)}</p>
                      <p className={styles.Label2}>{this.getLabelDate(labelDates[centerDate - 1] || now, false)}</p>
                      {
                        times[centerDate - 1]?.times.map(item => {
                          const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId)
                          const currentItem = moment(item.time)
                          const day = currentItem.isSame(moment(), 'day') || currentItem.isSame(moment().add(1, 'day'), 'day') || currentItem.isSame(moment().add(2, 'day'), 'day')
                          const discountImg = day ? <img className={styles.discount} alt="icon" src={discount} /> : null

                          return (
                            <div
                              role="button"
                              tabIndex={0}
                              onClick={() => this.trackEvent(item)}
                              key={item.time}
                              className={styles.Time}
                            >
                              {time}
                              {discountImg}
                            </div>
                          )
                        })
                      }
                    </>
                  )
                  : <></>
                }
              </div>
              <div className={styles.Item}>
                {Array.isArray(times) && Array.isArray(times[centerDate]?.times)
                  && times[centerDate]?.times.length > 0
                  ? (
                    <>
                      <p className={styles.Label}>{this.getLabelDate(labelDates[centerDate] || now, true)}</p>
                      <p className={styles.Label2}>{this.getLabelDate(labelDates[centerDate] || now, false)}</p>
                      {
                        times[centerDate]?.times.map(item => {
                          const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId)
                          const currentItem = moment(item.time)
                          const day = currentItem.isSame(moment(), 'day') || currentItem.isSame(moment().add(1, 'day'), 'day') || currentItem.isSame(moment().add(2, 'day'), 'day')
                          const discountImg = day ? <img className={styles.discount} alt="icon" src={discount} /> : null

                          return (
                            <div
                              role="button"
                              tabIndex={0}
                              onClick={() => this.trackEvent(item)}
                              key={item.time}
                              className={styles.Time}
                            >
                              {time}
                              {discountImg}
                            </div>
                          )
                        })
                      }
                    </>
                  )
                  : <></>
                }
              </div>
              <div className={styles.Item}>
                {Array.isArray(times) && Array.isArray(times[centerDate + 1]?.times)
                  && times[centerDate + 1]?.times.length > 0
                  ? (
                    <>
                      <p className={styles.Label}>{this.getLabelDate(labelDates[centerDate + 1] || now, true)}</p>
                      <p className={styles.Label2}>{this.getLabelDate(labelDates[centerDate + 1] || now, false)}</p>
                      {
                        times[centerDate + 1]?.times.map(item => {
                          const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId)
                          const currentItem = moment(item.time)
                          const day = currentItem.isSame(moment(), 'day') || currentItem.isSame(moment().add(1, 'day'), 'day') || currentItem.isSame(moment().add(2, 'day'), 'day')
                          const discountImg = day ? <img className={styles.discount} alt="icon" src={discount} /> : null

                          return (
                            <div
                              role="button"
                              tabIndex={0}
                              onClick={() => this.trackEvent(item)}
                              key={item.time}
                              className={styles.Time}
                            >
                              {time}
                              {discountImg}
                            </div>
                          )
                        })
                      }
                    </>
                  )
                  : <></>
                }
              </div>
            </div>
          )
        }
      </>
    )
  }

  /**
   * Track Event
   * @param {*} time .
   * @return {void}
   */
  trackEvent(time) {
    const { selectTime, patient, branch } = this.props
    selectTime(time)
    const data = {
      distinct_id: patient.CustomerId,
      customer_id: patient.CustomerId,
      country_ops: patient.Country_Ops,
      center_name: branch.Center_Name,
      platform: 'appointment_platform_v2',
      public_key: patient.PublicKey,
      appointment_date: time
    }
    setTrackingPatient(patient, { name: 'Old Appointment Platform - Day And Time Selected', data })
  }

  /**
   * Get Label Date
   * @param {*} dateText Date text format
   * @param {*} isNameDay Flag is name day
   * @returns {string} Label
   */
  getLabelDate(dateText, isNameDay) {
    const isDesktop = window.innerWidth > DESKTOP_WIDTH
    let label = ''
    try {
      if (dateText) {
        const dateParts = dateText.split(' ')
        label = isNameDay ? dateParts[0] : `${dateParts[1]} ${isDesktop ? dateParts[2] : dateParts[2].replace('septiembre', 'sept.')}`
      }
    } catch (err) {
      console.log(err)
    }
    return label
  }

  containerDesktop = () => {
    const {
      dates, times, centerDate, labelDates
    } = this.state
    const { patient, branch } = this.props
    const country = patient.Country_Ops
    const one = moment().format('dddd DD MMMM')
    let dateTwo = moment().add(1, 'days')
    let dateThree = moment().add(2, 'days')

    let currentDate = null
    if (Array.isArray(dates) && dates[centerDate - 1]) {
      currentDate = dates[centerDate - 1]
      dateTwo = moment(currentDate).add(1, 'days')
      dateThree = moment(currentDate).add(2, 'days')
    }
    if (Array.isArray(dates) && dates[centerDate]) {
      currentDate = dates[centerDate]
      dateThree = moment(currentDate).add(1, 'days')
    }
    const two = dateTwo.format('dddd DD MMMM')
    const three = dateThree.format('dddd DD MMMM')

    const appointmentTypeId = branch && branch.Appointment_Type_Id ? branch.Appointment_Type_Id : false
    return (
      <>
        {this.state.showPriorityBooking
          ? (
            <div>
              <div className={styles.priorityDatesContainer}>
                <div className={styles.Item} />
                <div className={styles.Item} />
                <div className={styles.Item} />
                <div className={styles.Item} />
                <div className={styles.Item} />
              </div>
              <div className={styles.bookingPriorityContainer}>
                <div className={styles.bookingTitle}>{messages.bookingTitle}</div>
                <p className={styles.bookingDescription}>
                  {messages.bookingDescription}
                </p>
                <Button
                  type="button"
                  disabled={this.state.priorityBookingCta}
                  size="medium"
                  color="red"
                  label={messages.bookingCta}
                  href="https://wa.me/525571000303?text=Hola%2C%20necesito%20ayuda%20para%20encontrar%20horario%20para%20mi%20cita%20Moons%20"
                  target="_blank"
                />
              </div>
            </div>
          ) : (
            <div className={styles.ContainerDesktop}>
              <div className={styles.Item}>
                {Array.isArray(times) && Array.isArray(times[centerDate - 1]?.times) && times[centerDate - 1]?.times.length > 0
                  ? (
                    <>
                      <p className={styles.Label}>{this.getLabelDate(labelDates[centerDate - 1] || one, true)}</p>
                      <p className={styles.Label2}>{this.getLabelDate(labelDates[centerDate - 1] || one, false)}</p>
                      {times[centerDate - 1]?.times.map(item => {
                        const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId)
                        const currentItem = moment(item.time)
                        const day = currentItem.isSame(moment(), 'day') || currentItem.isSame(moment().add(1, 'day'), 'day') || currentItem.isSame(moment().add(2, 'day'), 'day')
                        const discountImg = day ? <img className={styles.discount} alt="icon" src={discount} /> : null

                        return (
                          <div
                            role="button"
                            tabIndex={0}
                            onClick={() => this.trackEvent(item)}
                            key={item.time}
                            className={styles.Time}
                          >
                            {time}
                            {discountImg}
                          </div>
                        )
                      })}
                    </>
                  )
                  : <></>
                }
              </div>
              <div className={styles.Item}>
                {Array.isArray(times) && Array.isArray(times[centerDate]?.times) && times[centerDate]?.times.length > 0 ? (
                  <>
                    <p className={styles.Label}>{this.getLabelDate(labelDates[centerDate] || two, true)}</p>
                    <p className={styles.Label2}>{this.getLabelDate(labelDates[centerDate] || two, false)}</p>
                    {
                      times[centerDate]?.times.map(item => {
                        const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId)
                        const currentItem = moment(item.time)
                        const day = currentItem.isSame(moment(), 'day') || currentItem.isSame(moment().add(1, 'day'), 'day') || currentItem.isSame(moment().add(2, 'day'), 'day')
                        const discountImg = day ? <img className={styles.discount} alt="icon" src={discount} /> : null

                        return (
                          <div
                            role="button"
                            tabIndex={0}
                            onClick={() => this.trackEvent(item)}
                            key={item.time}
                            className={styles.Time}
                          >
                            {time}
                            {discountImg}
                          </div>
                        )
                      })
                    }
                  </>
                )
                  : <></>
                }
              </div>
              <div className={styles.Item}>
                {Array.isArray(times) && Array.isArray(times[centerDate + 1]?.times) && times[centerDate + 1]?.times.length > 0 ? (
                  <>
                    <p className={styles.Label}>{this.getLabelDate(labelDates[centerDate + 1] || three, true)}</p>
                    <p className={styles.Label2}>{this.getLabelDate(labelDates[centerDate + 1] || three, false)}</p>
                    {times[centerDate + 1]?.times.map(item => {
                      const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId)
                      const currentItem = moment(item.time)
                      const day = currentItem.isSame(moment(), 'day') || currentItem.isSame(moment().add(1, 'day'), 'day') || currentItem.isSame(moment().add(2, 'day'), 'day')
                      const discountImg = day ? <img className={styles.discount} alt="icon" src={discount} /> : null

                      return (
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => this.trackEvent(item)}
                          key={item.time}
                          className={styles.Time}
                        >
                          {time}
                          {discountImg}
                        </div>
                      )
                    })
                    }
                  </>
                )
                  : <></>
                }
              </div>
              <div className={styles.Item}>
                {Array.isArray(times) && Array.isArray(times[centerDate + 2]?.times) && times[centerDate + 2]?.times.length > 0 ? (
                  <>
                    <p className={styles.Label}>{this.getLabelDate(labelDates[centerDate + 2] || three, true)}</p>
                    <p className={styles.Label2}>{this.getLabelDate(labelDates[centerDate + 2] || three, false)}</p>
                    {times[centerDate + 2]?.times.map(item => {
                      const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId)
                      const currentItem = moment(item.time)
                      const day = currentItem.isSame(moment(), 'day') || currentItem.isSame(moment().add(1, 'day'), 'day') || currentItem.isSame(moment().add(2, 'day'), 'day')
                      const discountImg = day ? <img className={styles.discount} alt="icon" src={discount} /> : null

                      return (
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => this.trackEvent(item)}
                          key={item.time}
                          className={styles.Time}
                        >
                          {time}
                          {discountImg}
                        </div>
                      )
                    })
                    }
                  </>
                )
                  : <></>
                }
              </div>
              <div className={styles.Item}>
                {Array.isArray(times) && Array.isArray(times[centerDate + 3]?.times) && times[centerDate + 3]?.times.length > 0 ? (
                  <>
                    <p className={styles.Label}>{this.getLabelDate(labelDates[centerDate + 3] || three, true)}</p>
                    <p className={styles.Label2}>{this.getLabelDate(labelDates[centerDate + 3] || three, false)}</p>
                    {times[centerDate + 3]?.times.map(item => {
                      const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId)
                      const currentItem = moment(item.time)
                      const day = currentItem.isSame(moment(), 'day') || currentItem.isSame(moment().add(1, 'day'), 'day') || currentItem.isSame(moment().add(2, 'day'), 'day')
                      const discountImg = day ? <img className={styles.discount} alt="icon" src={discount} /> : null

                      return (
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => this.trackEvent(item)}
                          key={item.time}
                          className={styles.Time}
                        >
                          {time}
                          {discountImg}
                        </div>
                      )
                    })
                    }
                  </>
                )
                  : <></>
                }
              </div>
            </div>
          )
        }
      </>
    )
  }

  /**
   * Change Date
   * @param {String} dir .
   * @return {void}
   */
  changeDate = (dir) => {
    const { dates, centerDate, isLimit } = this.state
    const isDesktop = window.innerWidth > DESKTOP_WIDTH
    if (isDesktop) {
      if (dir === 'left' && dates[centerDate - 2]) {
        if (this.state.showPriorityBooking) {
          this.setState({ showPriorityBooking: false })
        } else {
          this.setState({ centerDate: centerDate - 1 })
        }
      } else if (dir === 'right' && dates[centerDate + 4]) {
        this.setState({ centerDate: centerDate + 1 })
      } else {
        if (!isLimit) {
          this.calculateDates()
          return
        }
        if (dir === 'right' && !this.state.showPriorityBooking && this.state.enablePriorityBooking) {
          this.setState({ showPriorityBooking: true })
        } else if (dir === 'left' && this.state.showPriorityBooking) {
          this.setState({ centerDate: centerDate - 1 })
          this.setState({ showPriorityBooking: !(dates?.length > 0) })
        }
      }
      return
    }

    if (dir === 'left' && dates[centerDate - 2]) {
      if (this.state.showPriorityBooking) {
        this.setState({ showPriorityBooking: false })
      } else {
        this.setState({ centerDate: centerDate - 1 })
      }
    } else if (dir === 'right' && dates[centerDate + 3]) {
      this.setState({ centerDate: centerDate + 1 })
    } else {
      if (!isLimit) {
        this.calculateDates()
        return
      }
      if (dir === 'right' && !this.state.showPriorityBooking && this.state.enablePriorityBooking) {
        this.setState({ showPriorityBooking: true })
      }
    }
  }

  /**
   * Render
   * @returns {void}
   */
  render() {
    const {
      loader, dates, centerDate, loadingTimes, modal, showPriorityBooking, enablePriorityBooking, priorityBookingCta
    } = this.state
    const { promoAppointment } = this.props
    const isDesktop = window.innerWidth > DESKTOP_WIDTH
    let showLeft = !!dates[centerDate - 2]
    let showRight = !!dates[centerDate]

    if (isDesktop) {
      if (!dates[centerDate - 2]) {
        showLeft = false
      }
      if (!dates[centerDate + 2]) {
        if (!showPriorityBooking && enablePriorityBooking) {
          showRight = true
        } else {
          showRight = false
        }
      }
    } else if (!dates[centerDate + 2]) {
      if (!showPriorityBooking && enablePriorityBooking) {
        showRight = true
      }
    }

    if (loader) {
      return (
        <div className={styles.ContainerLoader}>
          <PageLoader />
        </div>
      )
    }
    if (isEmpty(dates)) {
      return (
        <div className={styles.EmptyContainer}>
          {this.backComponent()}

          <img
            className={styles.MoonIcon}
            alt="icon"
            src={moonGray}
          />
          <p className={styles.bookingTitle}>
            {messages.empty}
          </p>
          {/* <Button
            type="button"
            id="choose-center"
            fullWidth={!isDesktop}
            size="medium"
            color="red"
            label={messages.chooseCenter}
            onClick={() => nextStep(true)}
          />  */}
          <p className={styles.bookingDescription}>
            {messages.bookingDescription}
          </p>
          <Button
            type="button"
            disabled={priorityBookingCta}
            size="medium"
            color="red"
            label={messages.bookingCta}
            href="https://wa.me/525571000303?text=Hola%2C%20necesito%20ayuda%20para%20encontrar%20horario%20para%20mi%20cita%20Moons%20"
            target="_blank"
          />
        </div>
      )
    }

    return (
      <div className={styles.ContainerDates} ref={this.wrapperRef}>
        {promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN
         || promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA
         || promoAppointment === PROMO_APPOINMENT_VARIANT_MEDELLIN
         || promoAppointment === PROMO_APPOINMENT_VARIANT_BUCARAMANGA ? null : this.backComponent()}
        {this.itemChoose()}
        <p className={styles.Select}>{showPriorityBooking ? '' : messages.select}</p>
        <div className={styles.DatesContainer}>
          {loadingTimes && (
            <div className={styles.Spinner}>
              <PageLoader />
            </div>
          )}

          <div className={styles.AllDates}>
            <div
              role="button"
              tabIndex={0}
              className={styles.ImageArrow}
              onClick={() => this.changeDate('left')}
            >
              {showLeft ? (
                <img
                  className={styles.Left}
                  alt="logo"
                  src={arrow}
                />
              ) : (
                <img
                  className={styles.Left}
                  alt="logo"
                  src={leftGray}
                />
              )}
            </div>
            {isDesktop ? this.containerDesktop() : this.containerMobile()}
            <div
              role="button"
              tabIndex={0}
              onClick={() => this.changeDate('right')}
              className={`${styles.ImageArrow} ${styles.RightArrow}`}
            >
              {showRight ? (
                <img
                  className={styles.Right}
                  alt="logo"
                  src={right}
                />
              ) : (
                <img
                  className={styles.Right}
                  alt="logo"
                  src={rightGray}
                />
              )}
            </div>
          </div>
        </div>
        <FullModal
          open={modal.showModal}
          content={modal.content}
        />
      </div>
    )
  }
}

export default ChooseDates
