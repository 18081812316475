import React from 'react'
import ReactDOM from 'react-dom'
import "@mymoons/ui-library/dist/library.min.css"
import './fonts.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.unregister()
