/**
 * Data layer track Function
 * @param { object } patient .
 * @param { object } data .
 * @return {void}
 */
export const setTrackingPatient = async (patient,data) => {
  if (patient && patient.CustomerId) {

    const properties = {
      customerId: patient.CustomerId
    }
    if (patient.Email) {
      properties.email = patient.Email
    }
    if (patient.Phone) {
      properties.phone = patient.Phone
    }
    if (patient.Patient_Name) {
      properties.name = patient.Patient_Name
    }
    try {
      window.localStorage.setItem('CustomerId', patient.CustomerId)
      window?.dataLayer?.push({ 'event': 'loadPixel' });
    } catch (err) {
      console.log(err)
    }
  }
}

/**
 * Tracking Rudder Function
 * @param { string } name .
 * @param { object } data .
 * @return {void}
 */
export const setTrackingRudder = (name, data) => {
  try {
    if (window.rudderanalytics) {
      window.rudderanalytics.track(name, data)
    }
  } catch {
    console.log('Error in rudderanalytics')
  }
}

/**
 * Tracking identify Rudder Function
 * @param { string } userId .
 * @param { object } data .
 * @return {void}
 */
export const setIdentifyRudder = (userId, data) => {
  try {
    if (window.rudderanalytics) {
      window.rudderanalytics.identify(userId, data);
    }
  } catch {
    console.log('Error in rudderanalytics');
  }
};
